<template>
  <div class="facility-room-type-container" :style="{height: height, maxHeight: maxHeightStyle}">
    <div class="d-flex flex-wrap align-center" v-if="!$apollo.loading">
      <div class="mt-5 mb-4">
        <div class="flex-header">
          <v-btn class="flex-header_icon" @click="chevMonth()" icon
            ><v-icon size="35">mdi-chevron-double-left</v-icon></v-btn
          >
          <v-btn class="flex-header_icon" @click="nextMonth()" icon
            ><v-icon size="35">mdi-chevron-double-right</v-icon></v-btn
          >
          <v-btn outlined small class="mr-5 ml-1 btn-dark" color="var(--text__gray)" @click="chooseToday">
            {{ $t("common.toDay") }}
          </v-btn>

          <!-- Date picker -->
          <v-menu
            v-model="menuDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on }">
              <div>
                <v-icon class="text-dark">mdi-calendar-month</v-icon>
                <v-btn
                  v-on="on"
                  small
                  class="btn--date mr-5"
                  >{{ datePicker | jaLongDate }}</v-btn
                >
              </div>
            </template>
            <v-date-picker
              :rules="reservationCalendar.daysOfWeek"
              v-model="datePicker"
              :first-day-of-week="0"
              :locale="$i18n.locale"
              scrollable
              @input="menuDatePicker = false"
              class="v-date-picker-custom"
            ></v-date-picker>
          </v-menu>
          <!--  -->

          <span class="text-dark">{{ $t("common.period") }}</span>
          <v-select
            :items="timeList"
            v-model="timeSelected"
            dense
            small
            item-text="text"
            item-value="number"
            color="var(--text__gray)"
            outlined
            hide-details
            class="v-select-custom ml-1 --sort"
          ></v-select>

          <template v-if="!fixedFacility">
            <span class="ml-5 text-dark">{{ $t("common.facility") }}</span>
            <v-select
              :items="facilityListItem"
              item-text="name"
              item-value="id"
              v-model="facilityId"
              placeholder="未選択"
              dense
              small
              color="var(--text__gray)"
              outlined
              hide-details
              class="v-select-custom ml-1 facility-list"
            >
              <template v-slot:item="{ item }">
                <div class="facility-select-item" :class="item.cssClass">{{ item.name }}</div>
              </template>
            </v-select>
          </template>
        </div>
        <div class="flex-header justify-end">
          <slot name="additionalInfo" />
        </div>
      </div>
      <div class="action-area">
        <slot name="actions" />
      </div>
    </div>
    <div class="main_calendar mx-0 my-0" :class="{ slim }" v-if="!$apollo.loading">
      <facility-calendar
        ref="facilityCalendar"
        v-for="facilityId in facilityIds"
        :defaultValues="defaultValues"
        :key="facilityId"
        :fromDate="fromDate"
        :toDate="endDate"
        :facilityId="facilityId"
        :roomTypeId="roomTypeId"
        :hideMwCol="hideMwCol"
        :hideCancelWait="hideCancelWait"
        :double="double"
        :excludeRoomTypesWithNoListedRooms="excludeRoomTypesWithNoListedRooms"
        @facilityCompleted="completed"
        @roomTypeCompleted="emitRoomTypeCompleted"
      >
        <template v-slot="props">
          <slot
            v-bind="props"
            :fromDate="fromDate"
            :toDate="endDate"
          />
        </template>
      </facility-calendar>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { isoDate } from '@/utils/dateUtil'
import { translateFacilityType, getOrderOfFacilityType } from '@/constants/facilityType'
import gql from 'graphql-tag'
import FacilityCalendar from './FacilityCalendar.vue'

export default {
  name: 'FacilityRoomTypeCalendar',
  components: {
    FacilityCalendar
  },
  props: {
    hideMwCol: Boolean,
    hideCancelWait: Boolean,
    double: Boolean,
    height: String,
    defaultFacilityId: Number,
    defaultValues: {
      type: Object,
      default: null
    },
    allowAll: Boolean,
    slim: Boolean,
    fixedFacility: Boolean,
    excludeRoomTypesWithNoListedRooms: Boolean
  },
  data () {
    return {
      checkOutDate: '',
      facilityId: this.allowAll ? null : undefined,
      roomTypeId: null,
      facilityList: [],
      forTimeFilterSelected: 1,
      timeSelected: '2 weeks',
      timeList: [{
        text: '1週間',
        number: '1 week'
      }, {
        text: '2週間',
        number: '2 weeks'
      }, {
        text: '1ヶ月',
        number: '1 month'
      }, {
        text: '3ヶ月',
        number: '3 months'
      },
      {
        text: '6ヶ月',
        number: '6 months'
      }],
      completedFacilities: {},
      datePicker: new Date().toISOString().substr(0, 10),
      menuDatePicker: false,
      reservationCalendar: {
        daysOfWeek: [(v) => !!v || this.$t('rules.isRequired')]
      },
      maxHeight: null
    }
  },
  mounted () {
    this.getFac()
    if (this.defaultValues) {
      this.facilityId = this.defaultValues.facilityId ?? this.facilityId
      this.datePicker = this.defaultValues.fromDate ?? this.datePicker
      this.checkOutDate = this.defaultValues.toDate ?? this.checkOutDate
      this.roomTypeId = this.defaultValues.roomTypeId ?? this.roomTypeId
    }
    // determine the the height to fill the container
    const { top } = this.$el.getBoundingClientRect()
    this.maxHeight = window.innerHeight - top
  },

  computed: {
    facilityIds () {
      if (typeof this.facilityId === 'undefined') {
        // the facilityId is in the initial state. let the user select it because the query is heavy (don't want to show all facilities unless requested)
        return []
      }
      if (typeof this.facilityId === 'number') {
        return [this.facilityId]
      } else {
        const ids = Array.isArray(this.facilityId) ? this.facilityId : this.filteredFacilities.map(f => f.id)
        // we want to make calendars loaded one by one. So return only the ind===0 or the previous one has been loaded
        return ids
          .filter((_, ind) => ind === 0 || !!this.completedFacilities[ids[ind - 1]])
      }
    },
    fromDate () {
      return isoDate(this.datePicker)
    },
    addMomentArgs () {
      const [num, period] = this.timeSelected.split(' ')
      return [
        Number(num), period
      ]
    },
    endDate () {
      return moment(this.fromDate, 'YYYY-MM-DD')
        .add(...this.addMomentArgs)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
    },

    filteredFacilities () {
      let facilities = this.facilities
      if (this.defaultValues?.arrListRoomType?.length) {
        facilities = facilities.filter(({ id }) => {
          return this.defaultValues.arrListRoomType.map((rt) => rt.facilityId).includes(id)
        })
      }
      return facilities
    },

    facilityListItem () {
      const copied = this.filteredFacilities.slice().map((f, index) => ({ ...f, index }))
      copied.sort((a, b) => {
        const orderByType = getOrderOfFacilityType(a.type) - getOrderOfFacilityType(b.type)
        if (orderByType) return orderByType
        return a.index - b.index
      })
      if (this.allowAll) {
        const datas = {
          id: null,
          name: '全て'
        }
        copied.unshift(datas)
      }
      let currentType = null
      copied.forEach((item, index) => {
        if (item.type !== currentType) {
          currentType = item.type
          copied.splice(index, 0, {
            id: this.facilities.filter(f => f.type === item.type).map(f => f.id),
            name: translateFacilityType(item.type),
            cssClass: 'select-item-facility-type'
          })
        }
      })

      return copied.filter(
        (item) => !/PT$/.test(item.name) && !/ポイント提携$/.test(item.name)
      )
    },
    maxHeightStyle () {
      return `${this.maxHeight - 5}px`
    }
  },

  watch: {
    facilityId (newVal) {
      localStorage.setItem('facilityId', JSON.stringify(this.facilityId))
      const arrNewVal = newVal ? (Array.isArray(newVal) ? newVal : [newVal])
        : newVal
      this.$emit('facilityIdChange', arrNewVal)
      if (newVal) {
        Object.keys(this.completedFacilities).forEach(key => {
          if (!arrNewVal.includes(key)) {
            this.$delete(this.completedFacilities, key)
          }
        })
      } else {
        // do nothing
      }
    },
    facilities (newArr) {
      // if there is only 1 facility in the list, just select that facility
      if (newArr.length === 1) {
        this.facilityId = newArr[0].id
      }
    }
  },

  methods: {
    chooseToday () {
      this.datePicker = this.$today()
    },

    getFac () {
      if (JSON.parse(localStorage.getItem('statusSetCalenDar'))) {
        this.facilityId = JSON.parse(localStorage.getItem('facilityId'))
      }
    },

    completed (payload) {
      const { facilityId } = payload
      this.$set(this.completedFacilities, facilityId, { loaded: true })
      this.$emit('completed', payload)
    },

    refreshRoomTypes (roomTypeIds) {
      this.$refs.facilityCalendar.forEach(cal => {
        if (typeof cal.refreshRoomTypes === 'function') {
          cal.refreshRoomTypes(roomTypeIds)
        }
      })
    },

    chevMonth () {
      this.datePicker = moment(this.datePicker, 'YYYY-MM-DD')
        .subtract(...this.addMomentArgs)
        .format('YYYY-MM-DD')
    },

    nextMonth () {
      this.datePicker = moment(this.datePicker, 'YYYY-MM-DD')
        .add(...this.addMomentArgs)
        .format('YYYY-MM-DD')
    },

    emitRoomTypeCompleted (payload) {
      this.$emit('roomTypeCompleted', payload)
    }
  },
  apollo: {
    facilities: {
      query: gql`query {
        facilityList{
          id
          name
          shortName
          type
        }
      }`,
      update: (data) => data.facilityList
    }
  }
}
</script>

<style scoped lang="scss">
.main_calendar {
  overflow: auto;
  padding: 0;
  flex-grow: 1;
}
.flex-header {
  padding-right: 10px;
}
.action-area {
  position: absolute;
  z-index: 99;
  right: 50px;
}
::v-deep {
  .cus-select .theme--light {
    top:2px !important
  }

  .theme--light.v-chip:not(.v-chip--active) {
    background: none !important;
}
  .v-select__selections {
    height: 50px !important;
    width: 173px !important;
    color: #000 !important;
  }
 .v-input__icon--append {
    color: red !important;
  }
  .v-select-custom.--sort {
    max-width: 88px;
  }
  .v-expansion-panel-header__icon .v-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
  .v-expansion-panel-content {
    margin-top: -62px;
  }
  .v-expansion-panel-content__wrap {
    margin-left: 100px !important;
  }
}
.v-application p {
  margin-bottom: 0 !important;
}
.calendar-custom_item:last-child {
  border-bottom: 1px solid $calendar-border-color;
  padding: 0 !important;
}
.btn-custom-red {
  background-color: red !important;
  color: #fff;
}
.custom-item {
  border: solid 3px red !important;
}
.calendar-custom_item_txt {
  margin: 0 10px;
}
.custom-text {
  text-align: left !important;
}
.custom-text-expan {
  position: absolute;
  top: 5px;
}
.text-button{
  color: #ffffff
}
.custom-div-farth {
  border-bottom: none !important;
}
.facility-room-type-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.facility-select-item {
  padding-left: 10px;
  &.select-item-facility-type {
    padding-left: 0;
    color: var(--text__primary);
    font-weight: 500;
  }
}
</style>
