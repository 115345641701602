<template>
  <div>
    <template v-if="$apollo.loading">
      <div>読み込み中</div>
    </template>
    <template v-else>
      <div class="facility-calendar-facilty-container" :class="extraClass">
        <div class="t-calendar-item --header facility-header">
          <div class="d-flex ">
            <div class="t-calendar-item__title">
              {{ facility.name }}
            </div>
            <div class="t-calendar-item__data-item year-month"
              v-for="(date, ind) in dateRange" :key="date"
              :class="{
                ['first-day-of-month']: $date(date) === 1,
                ['no-border']: $date(date) !== 1,
                first: ind === 0,
                last: ind === dateRange.length
              }"
            >
              <span v-if="ind === 0 || $dow(date) === 5 || $date(date) === 1">
                {{ date | jaYearMonth}}
              </span>
            </div>
          </div>
        </div>
        <div v-for="(roomType, idx) in roomTypes"
          :key="idx"
          class="calendar-block_mb"
        >
          <room-type-calendar
            :roomTypeLastIndexId ="idx > 0 ? roomTypes[idx -1].id : null"
            :ref="getRefOfCalendar(roomType.id)"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomTypeId="roomType.id"
            :mwCalendarCols="mwCalendarCols"
            :hideMwCol="hideMwCol"
            :hideCancelWait="hideCancelWait"
            @completed="completed"
          >
            <template v-slot:default="props">
              <slot
                v-bind="props"
                :facility="facility"
                :mwCalendarCols="mwCalendarCols"
              />
            </template>
          </room-type-calendar>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { getDateRangeArray } from '@/utils/dateUtil'
import RoomTypeCalendar from './RoomTypeCalendar.vue'

export default {
  components: { RoomTypeCalendar },
  props: {
    facilityId: Number,
    roomTypeId: Number,
    fromDate: String,
    toDate: String,
    hideMwCol: Boolean,
    hideCancelWait: Boolean,
    double: Boolean,
    excludeRoomTypesWithNoListedRooms: Boolean,
    defaultValues: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      roomTypes: [],
      mwWeekCalendar: [],
      facility: {},
      completedRoomTypes: {}
    }
  },
  watch: {
    roomTypes (rts) {
      if (rts.length === 0) {
        // emit the completed event if there is no room types
        this.emitComplete()
      }
    }
  },
  computed: {
    dateRange () {
      return getDateRangeArray(this.fromDate, this.toDate)
    },
    mwDateByDate () {
      const result = this.mwWeekCalendar.reduce((data, mw) => {
        data[mw.mwDate] = mw
        return data
      }, {})

      return result
    },
    mwCalendarCols () {
      const result = []
      // we must deal with skipped dates.
      // it is possible that week1=2022-01-01 to 2022-01-07, then week2 starts from 2022-01-09
      let currentData = null
      const pushToResult = () => {
        result.push(currentData)
        currentData = null
      }
      for (const date of this.dateRange) {
        const found = this.mwDateByDate[date]
        if (found) {
          const key = `${found.weekNo}|${found.latterHalf}`
          if (currentData && currentData.key !== key) {
            pushToResult()
          }

          if (!currentData) {
            currentData = {
              dates: [],
              weekNo: found.weekNo,
              latterHalf: found.latterHalf,
              order: new Date(date).getTime(),
              key
            }
          }
          currentData.dates.push(date)
        } else {
          if (currentData) {
            pushToResult()
          }

          result.push({
            dates: [date],
            order: new Date(date).getTime(),
            notDefined: true
          })
        }
      }
      if (currentData) { pushToResult() }
      return result
    },
    extraClass () {
      if (this.double) return 'double'
      return ''
    }
  },

  methods: {
    emitComplete () {
      if (this.roomTypes.length <= Object.keys(this.completedRoomTypes).length) {
        this.$emit('facilityCompleted', { facilityId: this.facilityId })
      }
    },
    completed (payload) {
      const { roomTypeId } = payload
      this.$set(this.completedRoomTypes, roomTypeId, payload ?? true)

      this.$emit('roomTypeCompleted', {
        facilityId: this.facilityId,
        ...payload
      })
      this.emitComplete()
    },

    refreshRoomTypes (roomTypeIds) {
      for (const roomTypeId of roomTypeIds) {
        let cmp = this.$refs[this.getRefOfCalendar(roomTypeId)]
        if (cmp) {
          if (cmp[0]) {
            cmp = cmp[0]
          }
          if (cmp.refresh) {
            cmp.refresh()
          }
        }
      }
    },

    getRefOfCalendar (roomTypeId) {
      return `roomTypeCalendar-${roomTypeId}`
    }
  },
  apollo: {
    facility: {
      query: gql`
        query ($facilityId: Int!) {
          facility(id: $facilityId) {
            id
            name
          }
        }
      `,
      update: (data) => data.facility,
      variables () {
        return { facilityId: this.facilityId }
      }
    },
    roomTypes: {
      query: gql`
        query ($facilityId: Int!, $hasAnyListedRooms: Boolean) {
          roomTypeList(facilityId: $facilityId hasAnyListedRooms: $hasAnyListedRooms) {
            id
            name
          }
        }
      `,
      update (data) {
        return data.roomTypeList.filter(roomType => {
          if (this.roomTypeId) {
            return roomType.id === this.roomTypeId
          }
          if ((this.defaultValues?.arrListRoomType?.length ?? 0) === 0) return true
          for (let i = 0; i < this.defaultValues?.arrListRoomType?.length ?? -1; i++) {
            if (this.defaultValues.arrListRoomType[i].roomTypeId === roomType.id) {
              return true
            }
          }
        })
        // return data.roomTypeList
      },
      variables () {
        return {
          facilityId: this.facilityId,
          hasAnyListedRooms: this.excludeRoomTypesWithNoListedRooms
        }
      }
    },
    mwWeekCalendar: {
      query: gql`
        query (
          $facilityId: Int!
          $fromDate: DateString!
          $toDate: DateString!
        ) {
          mwWeekCalendarList(
            fromDate: $fromDate
            toDate: $toDate
            facilityId: $facilityId
          ) {
            facilityId
            mwDate
            weekNo
            latterHalf
          }
        }
      `,
      update: (data) => data.mwWeekCalendarList,
      variables () {
        return {
          facilityId: this.facilityId,
          fromDate: this.fromDate,
          toDate: this.toDate
        }
      },
      skip () {
        return this.hideMwCol
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.facility-calendar-facilty-container {
  & ::v-deep {
    .year-month {
      font-size: 11px !important;
      color: #000000 !important;
      border-right: none;
      &.no-border {
        border-left: none;
        &.first {
          border-left: unset;
        }
        &.last {
          border-right: unset;
        }
      }
    }
  }
}
.facility-header {
  position: sticky;
  top: 0;
  z-index: 8;
}
.t-calendar-item__title {
  font-size: 12px !important;
  color: #000000 !important;
  font-weight: 500 !important;
}
.calendar-block_mb {
  margin-bottom: 2px;
}
</style>
